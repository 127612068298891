import React from 'react';
import '../../scss/global/footer.scss';

export default function Footer() {
    return (
        <footer>

            <h2>Copyright © 2023 Minecraft Server List</h2>

        </footer>
    );
}